import clsx from "clsx";
import * as React from "react";
import { useRef, useState } from "react";
import "./ImageSlider.css";

interface ImageSliderProps {
  images: string[];
  forceRatio?: boolean; // make sure that images are 3/2 ratio
  fixedHeight?: boolean;
}

export const ImageSlider: React.FC<ImageSliderProps> = React.memo(
  ({ images = [], forceRatio, fixedHeight }) => {
    const [page, setPage] = useState<number>(1);
    const myRef = useRef(null);

    const handleScroll = (e: any) => {
      setPage(Math.ceil(e.target.scrollLeft / e.target.clientWidth) + 1);
    };

    const renderPlaceholder = () => {
      return <div className="image-slider__item">Placeholder</div>;
    };

    const renderImages = (images: string[]) => {
      return images.map((image, i) => (
        <div key={i} className="image-slider__item">
          <img src={image} alt="" loading="lazy" />
        </div>
      ));
    };

    return (
      <div
        className={clsx("image-slider", {
          "image-slider--force-ratio": forceRatio,
          "image-slider--fixed-height": fixedHeight,
        })}
      >
        <div
          className="image-slider__items"
          ref={myRef}
          onScroll={handleScroll}
        >
          {images.length ? renderImages(images) : renderPlaceholder()}
        </div>
        {images.length > 1 && (
          <div className="image-slider__meta">
            {page}/{images.length}
          </div>
        )}
      </div>
    );
  }
);
