import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const PlaceNewView = React.lazy(() => import("./PlaceNewView"));

const PlaceNewViewLoadable: React.FC<RouteComponentProps<{ placeId: string }>> = (
    props
) => {
    return (
        <Suspense fallback={<Loading />}>
            <PlaceNewView {...props} />
        </Suspense>
    );
};

export default PlaceNewViewLoadable;
