import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const OrderView = React.lazy(() => import("./OrderView"));

const OrderViewLoadable: React.FC<
  RouteComponentProps<{ placeId: string; tableId: string }>
> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <OrderView {...props} />
    </Suspense>
  );
};

export default OrderViewLoadable;
