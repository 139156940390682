import { atom } from 'recoil';

export enum OVERLAY_VIEW {
    MENU_ITEM = 'MENU_ITEM',
    ORDER_ITEM = 'ORDER_ITEM',
    WIFI = 'WIFI',
    PAY = 'PAY',
    CHECK = 'CHECK',
    SHARE_ORDER = 'SHARE_ORDER',
    SCAN_TABLE_REQUEST = 'SCAN_TABLE_REQUEST',
    FEEDBACK = 'FEEDBACK',
    COOKIES = 'COOKIES',
    SIGNUP = 'SIGNUP',
    GOOGLE_REVIEWS = 'GOOGLE_REVIEWS',
    SIGNUP_SOCIAL = 'SIGNUP_SOCIAL',
    SIGNIN_SOCIAL = 'SIGNIN_SOCIAL',
    SIGNUP_USER = 'SIGNUP_USER',
    CASHBACK_OFFER = 'CASHBACK_OFFER',
    CASHBACK_REQUEST = 'CASHBACK_REQUEST',
    SUBSCRIPTION = 'SUBSCRIPTION'
}

export interface OverlayState {
    view?: OVERLAY_VIEW;
    metadata?: any;
    withError?: boolean;
}

const overlayState = atom<OverlayState>({
    key: 'overlayState',
    default: { view: undefined },
});

export { overlayState };
