import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";
import AdminGuard from "../AdminGuard/AdminGuard";

const AdminSubscriptionView = React.lazy(() => import("./AdminSubscriptionView"));

const AdminSubscriptionViewLoadable: React.FC<RouteComponentProps> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <AdminGuard><AdminSubscriptionView {...props} /></AdminGuard>
    </Suspense>
  );
};

export default AdminSubscriptionViewLoadable;
