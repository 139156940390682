import { atomFamily, selectorFamily } from "recoil";
import { getAddons, getCategories, getMenu, getSelections, getOrder } from "../api/firebase";

const menuFamily = atomFamily<MenuProps, string>({
    key: 'menuFamily',
    default: {
        menuMap: null,
        categoryMap: null,
        selectionMap: null,
        addonMap: null,
        menu: [],
        categories: [],
        selections: [],
        addons: [],
        order: { categories: [], menu: [] },
        isLoaded: false
    }
})


export interface MenuProps {
    menuMap: any;
    categoryMap: any;
    selectionMap: any;
    addonMap: any;
    menu: any[];
    categories: any[];
    selections: any[];
    addons: any[];
    order: { categories: string[], menu: string[] } | null;
    isLoaded: boolean;
}

const menuSelector = selectorFamily<MenuProps, string>({
    key: 'menuSelector',
    get: (placeId: string) => async ({ get }) => {
        const item = get(menuFamily(placeId));
        if (item.menuMap) {
            return item;
        } else {
            const menu = await getMenu({ placeId });
            const categories = await getCategories({ placeId });
            const selections = await getSelections({ placeId });
            const addons = await getAddons({ placeId });
            const order = await getOrder({ placeId })


            const menuMap = menu.reduce((acc: any, item: any) => {
                acc[item.id] = item;
                return acc;
            }, {});

            const categoryMap = categories.reduce((acc: any, item: any) => {
                acc[item.id] = item;
                return acc;
            }, {});

            const selectionMap = selections.reduce((acc: any, item: any) => {
                acc[item.id] = item;
                return acc;
            }, {});

            const addonMap = addons.reduce((acc: any, item: any) => {
                acc[item.id] = item;
                return acc;
            }, {});

            // console.log({ menuMap, categoryMap, addonMap, selectionMap, menu, categories, selections, addons, order, isLoaded: true })
            return { menuMap, categoryMap, addonMap, selectionMap, menu, categories, selections, addons, order, isLoaded: true };
        }
    }
})

export default menuSelector;