import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import "./QuantityField.css";

interface QuantityFieldProps {
  isVertical?: boolean;
  setFieldValue: (value: number) => void;
}

export const QuantityField: React.FC<
  QuantityFieldProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = React.memo(({ setFieldValue, ...props }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (typeof props.value === "number") {
      setValue(props.value);
    }
  }, [props.value]);

  const BEM = useMemo(() => {
    return clsx("quantity-field", {
      "quantity-field--vertical": props.isVertical,
    });
  }, [props.isVertical]);

  const updateValue = (value: number) => {
    setValue(value);
    setFieldValue(value);
  };

  const increment = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValue((currVal) => {
      const newVal = (currVal += 1);
      setFieldValue(newVal);
      return newVal;
    });
  };

  const decrement = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValue((currVal) => {
      if (currVal > 0) {
        const newVal = (currVal -= 1);
        setFieldValue(newVal);
        return newVal;
      }
      setFieldValue(0);
      return 0;
    });
  };

  return (
    <div className={BEM}>
      <button
        className="quantity-field__remove"
        type="button"
        onClick={decrement}
      >
        -
      </button>
      <input
        className="quantity-field__input"
        min="0"
        {...props}
        type="number"
        value={value}
        onChange={(e) => updateValue(+e.target.value)}
      />
      <button className="quantity-field__add" type="button" onClick={increment}>
        +
      </button>
    </div>
  );
});

export default QuantityField;
