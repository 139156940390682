import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";
import AdminGuard from "../AdminGuard/AdminGuard";

const AdminCashbackView = React.lazy(() => import("./AdminCashbackView"));

const AdminCashbackViewLoadable: React.FC<RouteComponentProps> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <AdminGuard><AdminCashbackView {...props} /></AdminGuard>
    </Suspense>
  );
};

export default AdminCashbackViewLoadable;
