import { atomFamily, selectorFamily } from "recoil"
import { recoilPersist } from "recoil-persist"

const { persistAtom } = recoilPersist()

export interface OrderProps {
    id: string;
    mealId: number;
    quantity: number;
    selects: {
        [selectId: string]: {
            [optionId: string]: boolean
        }
    };
    addons: {
        [addonId: string]: {
            [optionId: string]: {
                name: string; quantity: number
            }
        }
    }
}

const order = atomFamily({
    key: 'order_v2',
    default: [],
    effects_UNSTABLE: [persistAtom],
})

export const orderSelector = selectorFamily<OrderProps[], { placeId: string, tableId?: string }>({
    key: 'orderSelector',
    get: ({ placeId, tableId }) => ({ get }) => {
        const result = get(order(`${placeId}${tableId}`))
        return result
    },
    set: ({ placeId, tableId }) => ({ set }, newValue) => {
        if (!tableId) return [];
        set(order(`${placeId}${tableId}`), newValue)
    }
})

