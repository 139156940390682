import Sheet from "react-modal-sheet";
import { useRecoilState } from "recoil";
import { OVERLAY_VIEW, overlayState } from "../../recoil/overlayState";
import Meal from "../Meal/Meal";
import IconImg from "../../ui/IconImg/IconImg";
import Space from "../../ui/Space/Space";
import Text from "../../ui/Text/Text";
import Section from "../../ui/Section/Section";
import Button from "../../ui/Button/Button";
import { useMemo } from "react";
import SubHeading from "../SubHeading/SubHeading";
import { WithTranslation, withTranslation } from "react-i18next";
import { LOCALE_NS } from "../../utils/i18n";

import './PageSheet.css';

const PageSheet: React.FC<WithTranslation> = ({ t }) => {
  const [overlay, setOverlay] = useRecoilState(overlayState);
  const snap = useMemo(() => {
    switch (overlay.view) {
      case OVERLAY_VIEW.PAY:
      case OVERLAY_VIEW.CHECK:
      case OVERLAY_VIEW.WIFI:
      case OVERLAY_VIEW.SHARE_ORDER:
      case OVERLAY_VIEW.SCAN_TABLE_REQUEST:
      case OVERLAY_VIEW.FEEDBACK:
        return [300];
      case OVERLAY_VIEW.SIGNUP:
        return [400];
      default:
        return undefined;
    }
  }, [overlay]);

  const onClose = () => {
    setOverlay({ view: undefined });
  };

  const renderView = () => {
    switch (overlay.view) {
      case OVERLAY_VIEW.MENU_ITEM:
        return (
          <Meal
            orderId={overlay.metadata.orderId}
            mealId={overlay.metadata.mealId}
            tableId={overlay.metadata.tableId}
            placeId={overlay.metadata.placeId}
            onSubmitted={onClose}
            onClose={onClose}
          />
        );
      case OVERLAY_VIEW.ORDER_ITEM:
        return (
          <Meal
            orderId={overlay.metadata.orderId}
            mealId={overlay.metadata.mealId}
            tableId={overlay.metadata.tableId}
            placeId={overlay.metadata.placeId}
            onSubmitted={onClose}
            onClose={onClose}
          />
        );
      case OVERLAY_VIEW.WIFI:
        return (
          <Section>
            <Space>
              <IconImg align="center" name="wifi" size={48} />
              <Text size={14}>{t('general:pagesheet.wifi.name')}</Text>
              <Text size={22} bold>
                {overlay.metadata?.wifi?.name}
              </Text>
              <Text size={14}>{t('general:pagesheet.wifi.pass')}</Text>
              <Text size={22} bold>
                {overlay.metadata?.wifi?.password}
              </Text>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.PAY:
        return (
          <Section>
            <Space size={32}>
              {/* <Button type="apple" icon="apple">
                Pay
              </Button> */}
              <IconImg align="center" name="wave" size={48} />
              <Space>
                <Text size={22} bold>
                  {t('general:pagesheet.pay.desc')}
                </Text>
                <Text>
                  {t('general:pagesheet.pay.descNext')}
                </Text>
              </Space>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.CHECK:
        return (
          <Section>
            <Space size={32}>
              <IconImg align="center" name="wave" size={48} />
              <Space>
                <Text size={22} bold>
                  {t('general:pagesheet.check.desc')}
                </Text>
              </Space>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.FEEDBACK:
        return (
          <Section>
            <Space size={32}>
              <IconImg align="center" name="google" size={48} />
              <Space>
                <Text>
                  {t('general:pagesheet.feedback.desc')}
                </Text>
              </Space>
              <Button type="google" icon="google" href={overlay.metadata.google}>
                {t('general:pagesheet.feedback.link')}
              </Button>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.SHARE_ORDER:
        return (
          <Section>
            <Space size={32}>
              <Space>
                <Text size={22} bold>
                  {t('general:pagesheet.shareorder.desc')}
                </Text>
              </Space>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.SCAN_TABLE_REQUEST:
        return (
          <Section>
            <Space size={32}>
              <Space>
                <IconImg align="center" name="qr" size={48} />
                <Text>
                  {t('general:pagesheet.scantable.desc')}
                </Text>
              </Space>
            </Space>
          </Section>
        );
      case OVERLAY_VIEW.COOKIES:
        return (
          <Section>
            <Space size={32}>
              {/* <Button type="apple" icon="apple">
                Pay
              </Button> */}
              <IconImg align="center" name="cookies" size={48} />
              <Space>
                <Text>
                  {t('general:pagesheet.cookies.desc')}
                </Text>
                <Text>
                  {t('general:pagesheet.cookies.descNext')}
                </Text>
                <SubHeading title={t('general:pagesheet.cookies.links.title')} />
                <Space size={4}>
                  <a href="#"><Text color="green-dark">{t('general:pagesheet.cookies.links.terms')}</Text></a>
                  <a href="#"><Text color="green-dark">{t('general:pagesheet.cookies.links.privacy')}</Text></a>
                  <a href="#"><Text color="green-dark">{t('general:pagesheet.cookies.links.cookie')}</Text></a>
                </Space>
              </Space>
            </Space>
          </Section>
        )
      case OVERLAY_VIEW.SIGNUP:
        return (
          <Section>
            <Space size={32}>
              {/* <Button type="apple" icon="apple">
                Pay
              </Button> */}
              <IconImg align="center" name="menual" size={48} />
              <Space>
                <Text>
                  {t('general:pagesheet.signup.desc')}
                </Text>
                <Text>
                  {t('general:pagesheet.signup.descNext')}
                </Text>
                <a href="mailto:signup@menual.eu"><Text bold>signup@menual.eu</Text></a>
              </Space>
            </Space>
          </Section>
        )
      default:
        return null;
    }
  };

  const isOpen = !!overlay.view && (
    overlay.view !== OVERLAY_VIEW.ORDER_ITEM
    && overlay.view !== OVERLAY_VIEW.MENU_ITEM
    && overlay.view !== OVERLAY_VIEW.GOOGLE_REVIEWS
    && overlay.view !== OVERLAY_VIEW.SIGNUP_SOCIAL
    && overlay.view !== OVERLAY_VIEW.SIGNIN_SOCIAL
    && overlay.view !== OVERLAY_VIEW.SIGNUP_USER
    && overlay.view !== OVERLAY_VIEW.CASHBACK_OFFER
    && overlay.view !== OVERLAY_VIEW.CASHBACK_REQUEST
  );

  return (
    <Sheet isOpen={isOpen} onClose={() => onClose()} snapPoints={snap}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="page-sheet__content">
            <Space>
              {renderView()}
              <div className="page-sheet__close">
                <Button type="secondary" onClick={() => onClose()}>
                  {t('general:close')}
                </Button>
              </div>
            </Space>
          </div>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop />
    </Sheet>
  );
};

export default withTranslation([LOCALE_NS.GENERAL])(PageSheet);
