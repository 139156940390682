import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const HomeNewView = React.lazy(() => import("./HomeNewView"));

const HomeNewViewLoadable: React.FC<RouteComponentProps> = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <HomeNewView {...props} />
        </Suspense>
    );
};

export default HomeNewViewLoadable;
