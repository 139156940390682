import React, { Suspense } from "react";
const PlaceInfoView = React.lazy(() => import("./PlaceInfoView"));

const PlaceInfoViewLoadable: React.FC = (props) => {
  return (
    <Suspense fallback={null}>
      <PlaceInfoView {...props} />
    </Suspense>
  );
};

export default PlaceInfoViewLoadable;
