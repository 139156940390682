import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const VerifyOrderView = React.lazy(() => import("./VerifyOrderView"));

const VerifyOrderViewLoadable: React.FC<RouteComponentProps<{ placeId: string, orderId: string }>> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <VerifyOrderView {...props} />
    </Suspense>
  );
};

export default VerifyOrderViewLoadable;
