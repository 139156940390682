import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const LandingView = React.lazy(() => import("./LandingView"));

const LandingViewLoadable: React.FC<RouteComponentProps> = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <LandingView {...props} />
        </Suspense>
    );
};

export default LandingViewLoadable;
