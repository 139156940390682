import React from "react";
import clsx from "clsx";
import "./Tag.css";
import Icon, { IconNames } from "../Icon/Icon";

export interface StateProps {
  icon?: IconNames;
  highlighted?: boolean;
  className?: string;
  children?: React.ReactNode;
  variant?: 'default' | 'required' | 'optional'
}
export interface DispatchProps {
  href?: string;
  onClick?: () => void;
}
export type TagProps = StateProps & DispatchProps;

const Tag: React.FC<TagProps> = React.memo(
  ({ icon, highlighted, className = "", variant = 'default', children, href, onClick }) => {
    const TagEl = onClick ? "button" : (href ? "a" : "div");
    const BEM = clsx("tag", className, { "tag--highlighted": highlighted, [`tag--${variant}`]: variant });

    return (
      <TagEl type="button" className={BEM} onClick={onClick} href={href} target="_blank">
        {icon && <Icon className="tag__icon" name={icon} size={14} />}
        {children}
      </TagEl>
    );
  }
);

export default Tag;
