import { useRecoilValue } from "recoil";
import { authState } from "../../recoil/authState";

interface AdminGuardProps {
  children: React.ReactNode;
}

const AdminGuard = (props: AdminGuardProps) => {
  const { children } = props;
  const auth = useRecoilValue(authState);

  if (
    auth?.uid !== 'zy8PbRnAg2RWfOwM7vuq4wlfViG3'
    && auth?.uid !== 'VXgAnYsVgHXWqC85emN9hj6mGlV2'
  ) {
    return null;
  }

  return <>{children}</>
}

export default AdminGuard