import React from "react";
import { Suspense } from "react"
import { RouteComponentProps } from "react-router-dom";

const AccountView = React.lazy(() => import("./AccountView"));

const AccountViewLoadable = (props: RouteComponentProps<{ placeId?: string }>) => {
  return (
    <Suspense>
      <AccountView {...props} />
    </Suspense>
  )
}

export default AccountViewLoadable;