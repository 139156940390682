import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";
import AdminGuard from "../AdminGuard/AdminGuard";

const AdminView = React.lazy(() => import("./AdminView"));

const AdminViewLoadable: React.FC<RouteComponentProps<{ placeId: string }>> = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <AdminGuard><AdminView {...props} /></AdminGuard>
        </Suspense>
    );
};

export default AdminViewLoadable;
