import React from "react";
import { Suspense } from "react"
import { RouteComponentProps } from "react-router-dom";

const CashbackView = React.lazy(() => import("./CashbackView"));

const CashbackViewLoadable = (props: RouteComponentProps<{ placeId?: string }>) => {
  return (
    <Suspense>
      <CashbackView {...props} />
    </Suspense>
  )
}

export default CashbackViewLoadable;