import { SelectProps } from "../../../../hooks/useMenuNew";
import Btn from "../../../../ui/Btn/Btn";
import Space from "../../../../ui/Space/Space";
import Tag from "../../../../ui/Tag/Tag";
import SubHeading from "../../../SubHeading/SubHeading";
import MealSelectOption from "./MealSelectOption";

interface MealSelectsProps {
    selects: Array<SelectProps & { id: string }>;
    checked: { [selectId: string]: { [optionId: string]: boolean } };
    translation: { [id: string]: { name?: string } };
    toggleChecked(selectId: string, optionId: string): void;
}

const MealSelects: React.FC<MealSelectsProps> = (props) => {
    const { selects, checked, toggleChecked } = props;
    const isNoneSelected = !Object.keys(checked || {}).length;

    return <Space size={24} className="meal-selects">
        {
            selects.map(select => (
                <Space size={8} key={select.id}>
                    <SubHeading title={props.translation?.[select.id]?.name || select.name}>
                        {isNoneSelected ? <Tag variant="required" >Required</Tag> : <Btn size={24} type="success" icon="check" label="check" />}
                    </SubHeading>
                    <Space size={16}>
                        {select.options.map(option => (
                            <div key={option.id} className="meal-select">
                                <MealSelectOption
                                    selectId={select.id}
                                    option={option}
                                    multi={select.multi}
                                    checked={checked[select.id]?.[option.id]}
                                    translation={props.translation}
                                    toggleChecked={toggleChecked} />
                            </div>
                        ))}
                    </Space>
                </Space>
            ))
        }
    </Space>
}

export default MealSelects;