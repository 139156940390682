import React, { Suspense } from "react";
const Overlay = React.lazy(() => import("./Overlay"));

const OverlayLoadable: React.FC = (props) => {
  return (
    <Suspense fallback={null}>
      <Overlay {...props} />
    </Suspense>
  );
};

export default OverlayLoadable;
