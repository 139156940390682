import React from "react";
import cn from "clsx";
import "./Text.css";
import clsx from "clsx";

export interface TextProps {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  size?: 12 | 14 | 16 | 18 | 20 | 22 | 32 | 48;
  align?: "left" | "center" | "right";
  color?:
  | "primary"
  | "secondary"
  | "sub-color"
  | "bg"
  | "grey1"
  | "grey2"
  | "grey3"
  | "grey4"
  | "green-light"
  | "green-dark"
  | "liliac-dark"
  | "success"
  | "red"
  | "yellow"
  | "orange";
  uppercase?: boolean;
  nowrap?: boolean;
  italic?: boolean;
  bold?: boolean;
  underline?: boolean;
  capitalize?: boolean;
  block?: boolean;
  blurred?: boolean;
  linethrough?: boolean;
  id?: string;
  className?: string;
  children?: React.ReactNode;
  lines?: boolean;
}

const Text: React.FC<TextProps> = React.memo(
  ({
    tag,
    size = 16,
    align,
    color,
    uppercase,
    nowrap,
    italic,
    bold,
    underline,
    capitalize,
    block,
    blurred,
    linethrough,
    lines,
    id,
    className,
    children,
  }) => {
    const Tag = tag ? tag : "p";

    const BEM = () => {
      const styleSize = size ? `text--${size}` : "";
      const styleAlign = align ? `text--${align}` : "";
      const styleColor = color ? `text--${color}` : "";

      return cn("text", styleSize, styleAlign, styleColor, className, {
        "text--nowrap": nowrap,
        "text--uppercase": uppercase,
        "text--bold": bold,
        "text--underline": underline,
        "text--italic": italic,
        "text--capitalize": capitalize,
        "text--block": block,
        "text--blurred": blurred,
        "text--linethrough": linethrough,
        "text--lines": lines
      });
    };

    return (
      <Tag id={id} className={BEM()}>
        {children}
      </Tag>
    );
  }
);

export default Text;

export const TextHighlight: React.FC<TextProps> = (props) => {
  const align = props.align ? `text-wrapper--${props.align}` : "";

  return (
    <div className={clsx("text-wrapper", align)}>
      <div className="text-wrapper__bg">
        <Text {...props} />
      </div>
    </div>
  );
};
