import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";
import AdminGuard from "../AdminGuard/AdminGuard";

const AdminPlacesView = React.lazy(() => import("./AdminPlacesView"));

const AdminPlacesViewLoadable: React.FC<RouteComponentProps> = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <AdminGuard><AdminPlacesView {...props} /></AdminGuard>
        </Suspense>
    );
};

export default AdminPlacesViewLoadable;
