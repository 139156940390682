import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import OverlayLoadable from "./components/Overlay/OverlayLoadable";
import { APP_ROUTES } from "./routes";
import PlaceInfoViewLoadable from "./views/PlaceInfo/PlaceInfoviewLoadable";
import PageSheet from "./components/PageSheet/PageSheet";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authState } from "./recoil/authState";
import { getUser, onAuthStateChange } from "./api/firebase";
import { OVERLAY_VIEW, overlayState } from "./recoil/overlayState";
import { UserState, userState } from "./recoil/userState";

function App() {
  const { pathname } = useLocation();
  const [auth, setAuth] = useRecoilState(authState);
  const [overlay, setOverlay] = useRecoilState(overlayState);
  const setUser = useSetRecoilState(userState);

  const fetchUser = useCallback(
    async (uid: string) => {

      const user = (await getUser(uid)) as UserState;
      if (user) {
        setUser(user);
        setOverlay({ view: undefined });
      } else {
        setUser(undefined);
        setOverlay({ view: OVERLAY_VIEW.SIGNUP_USER });
      }

    },
    [setOverlay, setUser]
  );

  const appPage = useMemo(() => pathname.split('/').join('-'), [pathname]);
  const handleFocus = () => {
    const itemStr = localStorage.getItem('expireAt')
    if (!itemStr) {
      localStorage.setItem('expireAt', JSON.stringify({ expiry: new Date().getTime() + 1000 * 60 * 60 * 2 }));
      return null
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem('expireAt');
      localStorage.removeItem('recoil-persist');
      return null
    } else {
      localStorage.setItem('expireAt', JSON.stringify({ expiry: new Date().getTime() + 1000 * 60 * 60 * 2 }));
    }
  };

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  useEffect(() => {
    const unsubsribe = onAuthStateChange(setAuth);
    return () => unsubsribe();
  }, [setAuth]);


  useEffect(() => {
    if (auth && !auth.isAnonymous) {
      fetchUser(auth.uid);
    } else {
      setUser(undefined);
    }
  }, [auth, fetchUser, setUser]);

  return (
    <div className={"app app-" + appPage}>
      <Switch>
        {APP_ROUTES.map((route) => (
          <Route {...route} />
        ))}
        <Redirect to="/home" />
      </Switch>
      <OverlayLoadable />
      <PageSheet />
      <PlaceInfoViewLoadable />
    </div>
  );
}

export default App;
