import { useRecoilValue } from "recoil";
import { MealProps } from "../../../../hooks/useMenuNew";
import Space from "../../../../ui/Space/Space";
import MealAddonOption from "./MealAddonOption";
import menuSelector from "../../../../recoil/menuState";

interface MealAddonsProps {
    placeId: string;
    addonId: string;
    optionIds: number[]
    multi: boolean,
    checked?: { [optionId: number]: { quantity: number } };
    translation?: { [id: string]: { name?: string, desc?: string } },
    toggleChecked(addonId: string, optionId: number, optionName: string): void;
    setQuantity?(addonId: string, optionId: number, value: number): void;
}

const MealAddons: React.FC<MealAddonsProps> = ({ placeId, addonId, multi, optionIds, checked, translation, toggleChecked, setQuantity }) => {
    const menu = useRecoilValue(menuSelector(placeId));
    const options = optionIds.reduce((acc: MealProps[], optionId) => {
        const option = (menu.menuMap[optionId]);
        if (option) {
            acc.push(option);
        }
        return acc;
    }, []);

    return (
        <Space size={12}>
            {options.map(option => (
                <div key={option.id} className="meal-addon">
                    <MealAddonOption
                        addonId={addonId}
                        option={option}
                        multi={multi}
                        checked={!!checked?.[option.id]}
                        quantity={checked?.[option.id]?.quantity}
                        translation={translation}
                        toggleChecked={toggleChecked}
                        setQuantity={setQuantity} />
                </div>
            ))}
        </Space>
    );
}

export default MealAddons;