import React from "react";
import cn from "clsx";
import "./Check.css";
import Icon from "../../Icon/Icon";

interface CheckProps {
  large?: boolean;
}

const Check: React.FC<
  CheckProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = React.memo(({ large, children, ...props }) => {
  return (
    <label
      className={cn("check", {
        "check--checked": props.checked,
        [`checked--l`]: large,
      })}
    >
      <Icon className="check__icon" name="check" size={large ? 32 : 14} />
      <input className="check__input" {...props} />
      <span className="check__label">{children}</span>
    </label>
  );
});

export default Check;
