import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";

const VerifyOrdersView = React.lazy(() => import("./VerifyOrdersView"));

const VerifyOrdersViewLoadable: React.FC<RouteComponentProps<{ placeId: string }>> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <VerifyOrdersView {...props} />
    </Suspense>
  );
};

export default VerifyOrdersViewLoadable;
