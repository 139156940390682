import React from 'react';
import './SubHeading.css';

export interface SubHeadingProps {
    title: string;
    aside?: string;
    children?: React.ReactNode;
}

const SubHeading: React.FC<SubHeadingProps> = React.memo((props) => {
    return (
        <div className="sub-heading">
            <h3 className="sub-heading__title">{props.title}</h3>
            {props.aside && <p className="sub-heading__aside">{props.aside}</p>}
            {props.children && <div className="sub-heading__aside">{props.children}</div>}
        </div>
    );
});

export default SubHeading;
