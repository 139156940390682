import React from "react";
import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../ui/Loading/Loading";
import AdminGuard from "../AdminGuard/AdminGuard";

const AdminRolesView = React.lazy(() => import("./AdminRolesView"));

const AdminRolesViewLoadable: React.FC<RouteComponentProps> = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <AdminGuard><AdminRolesView {...props} /></AdminGuard>
    </Suspense>
  );
};

export default AdminRolesViewLoadable;
