import AdminViewLoadable from "./views/Admin/AdminViewLoadable";
import AdminCashbackViewLoadable from './views/AdminCashback/AdminCashbackViewLoadable';
import AdminRolesViewLoadable from './views/AdminRoles/AdminRolesViewLoadable';
import AdminSubscriptionsViewLoadable from './views/AdminSubscription/AdminSubscriptionViewLoadable';
import AdminPlacesViewLoadable from "./views/AdminPlaces/AdminPlacesViewLoadable";
// import HomeViewLoadable from "./views/Home/HomeViewLoadable";
import HomeNewViewLoadable from "./views/HomeNew/HomeNewViewLoadable";
import OrderViewLoadable from "./views/Order/OrderLoadable";
import VerifyOrderViewLoadable from "./views/VerifyOrder/VerifyOrderViewLoadable";
import VerifyOrdersViewLoadable from "./views/VerifyOrders/VerifyOrdersViewLoadable";
// import PlaceViewLoadable from "./views/Place/PlaceViewLoadable";
import PlaceNewViewLoadable from "./views/PlaceNew/PlaceNewViewLoadable";
import LandingViewLoadable from './views/Landing/LandingViewLoadable';
import AccountViewLoadable from "./views/Account/AccountViewLoadable";
import CashbackViewLoadable from "./views/Cashback/CashbackViewLoadable";

export type AppRouteNames = 'HOME' | 'ADMIN' | 'ADMIN_CASHBACK' | 'ADMIN_ROLES' | 'ADMIN_SUBSCRIPTION' | 'ADMIN_PLACE' | 'ACCOUNT' | 'VERIFY_ORDER' | 'VERIFY_ORDERS' | 'PLACE' | 'PLACE_ACCOUNT' | 'PLACE_CASHBACK' | 'PLACE_W_TABLE' | 'PLACE_W_TABLE_ACCOUNT' | 'ORDER' | 'LANDING';

export type AppRouteProps = {
    key: AppRouteNames;
    path: string;
    exact?: boolean;
    component: React.ComponentType<any>;
};


export type AppRoutes = {
    [key in AppRouteNames]: AppRouteProps;
};

export const APP_ROUTE: AppRoutes = {
    HOME: {
        key: "HOME",
        path: "/home",
        component: HomeNewViewLoadable,
    },
    // HOME_NEW: {
    //     key: "HOME_NEW",
    //     path: "/home-new",
    //     component: HomeNewViewLoadable,
    // },
    LANDING: {
        key: "LANDING",
        path: "/restaurant",
        component: LandingViewLoadable,
    },
    ADMIN_PLACE: {
        key: "ADMIN",
        path: "/zeus",
        component: AdminPlacesViewLoadable,
        exact: true
    },
    ADMIN_SUBSCRIPTION: {
        key: "ADMIN_SUBSCRIPTION",
        path: "/zeus/subscriptions",
        component: AdminSubscriptionsViewLoadable,
        exact: true
    },
    ADMIN_CASHBACK: {
        key: "ADMIN_CASHBACK",
        path: "/zeus/cashback",
        component: AdminCashbackViewLoadable,
        exact: true
    },
    ADMIN_ROLES: {
        key: "ADMIN_ROLES",
        path: "/zeus/roles",
        component: AdminRolesViewLoadable,
        exact: true
    },
    ADMIN: {
        key: "ADMIN",
        path: "/zeus/:placeId",
        component: AdminViewLoadable,
        exact: true
    },
    // PLACE_NEW: {
    //     key: "PLACE_NEW",
    //     path: "/v2/:placeId",
    //     component: PlaceNewViewLoadable,
    //     exact: true
    // },
    // PLACE_NEW_W_TABLE: {
    //     key: "PLACE_NEW_W_TABLE",
    //     path: "/v2/:placeId/:tableId",
    //     component: PlaceNewViewLoadable,
    //     exact: true
    // },
    ACCOUNT: {
        key: "ACCOUNT",
        path: "/account",
        component: AccountViewLoadable,
        exact: true,
    },
    VERIFY_ORDER: {
        key: "VERIFY_ORDER",
        path: "/:placeId/verify/orders/:orderId",
        component: VerifyOrderViewLoadable,
        exact: true
    },
    VERIFY_ORDERS: {
        key: "VERIFY_ORDER",
        path: "/:placeId/verify/orders",
        component: VerifyOrdersViewLoadable,
        exact: true
    },
    PLACE: {
        key: "PLACE",
        path: "/:placeId",
        component: PlaceNewViewLoadable,
        exact: true
    },
    PLACE_ACCOUNT: {
        key: "PLACE_ACCOUNT",
        path: '/:placeId/account',
        component: AccountViewLoadable,
        exact: true,
    },
    PLACE_CASHBACK: {
        key: "PLACE_CASHBACK",
        path: '/:placeId/cashback',
        component: CashbackViewLoadable,
        exact: true,
    },
    PLACE_W_TABLE: {
        key: "PLACE",
        path: "/:placeId/:tableId",
        component: PlaceNewViewLoadable,
        exact: true
    },
    PLACE_W_TABLE_ACCOUNT: {
        key: "PLACE_ACCOUNT",
        path: '/:placeId/:tableId/account',
        component: AccountViewLoadable,
        exact: true,
    },
    ORDER: {
        key: "ORDER",
        path: "/:placeId/:tableId/order",
        component: OrderViewLoadable,
        exact: true,
    }
}

export const APP_ROUTES = Object.values(APP_ROUTE)
