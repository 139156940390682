import { atomFamily, selectorFamily } from "recoil";
import { getTransletion } from "../api/firebase";

const translateFamily = atomFamily<{ [id: string]: { name?: string, desc?: string } }, { placeId: string, lang: string }>({
    key: 'menuFamily',
    default: undefined
})


const translateSelector = selectorFamily<{ [id: string]: { name?: string, desc?: string } }, { placeId: string, lang: string }>({
    key: 'translateSelector',
    get: ({ placeId, lang }: { placeId: string, lang: string }) => async ({ get }) => {
        const item = get(translateFamily({ placeId, lang }));
        if (item) {
            return item;
        } else {
            const translate = (await getTransletion({ placeId, lang })) as { [id: string]: { name?: string, desc?: string } };
            return translate;
        }
    }
})

export default translateSelector;