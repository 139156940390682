import Check from "../../../../ui/form/Check/Check"
import { currency } from "../../../../utils/currency";

const MealSelectOption: React.FC<{
    selectId: string,
    option: { id: string, name: string, extraCost?: number },
    multi: boolean,
    checked: boolean
    quantity?: number,
    translation?: { [id: string]: { name?: string, options?: { [key: string]: string } } };
    toggleChecked(selectId: string, optionId: string): void;
}> = ({ selectId, option, multi, checked, translation, toggleChecked }) => {
    return <div key={option.id} className="meal-addon-option">
        <Check
            name={selectId}
            type={multi ? "checkbox" : "radio"}
            value={option.id}
            checked={checked}
            onChange={() => toggleChecked(selectId, option.id)}
        >
            {translation?.[selectId]?.options?.[option.id] || option.name}
            {!!option.extraCost && (<span>+ {currency(option.extraCost)}</span>)}
        </Check>

    </div>
}

export default MealSelectOption;