// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp, collection, getDocs, doc, getDoc, setDoc, query, where, onSnapshot, or, and, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { FacebookAuthProvider, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { AuthState } from '../recoil/authState';
import { OAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBwuWeP7HbcO4ZAp74dPvmb5M1AROauDY",
  authDomain: "menu-visual.firebaseapp.com",
  projectId: "menu-visual",
  storageBucket: "menu-visual.appspot.com",
  messagingSenderId: "747891291235",
  appId: "1:747891291235:web:4b58e8831f636616193456",
  measurementId: "G-Z8ZSH4KY1M"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();
const provider = new GoogleAuthProvider();
const provider_fb = new FacebookAuthProvider();
const provider_apple = new OAuthProvider('apple.com');
export const auth = getAuth();


export async function getPlaces() {
  try {
    const menuRef = collection(db, "places");
    const querySnapshot = await getDocs(menuRef);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCashbacks() {
  try {
    const menuRef = collection(db, "cashbacks");
    const querySnapshot = await getDocs(menuRef);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCashback(cashbackId: string) {
  const docRef = doc(db, "cashbacks", cashbackId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}


// export async function getActiveSubscriptions() {
//   try {
//     const userSubscriptionsRef = collection(db, "subscriptions");
//     const q = query(userSubscriptionsRef, where("isPublished", "==", true));
//     const querySnapshot = await getDocs(q);
//     const d: any[] = [];
//     querySnapshot.forEach((doc) => {
//       d.push({ id: doc.id, ...doc.data() });
//     });
//     console.log(d);
//     return d as any[];
//   } catch (e) {
//     console.error(e);
//     return [];
//   }
// }

export interface PlaceCashback$Res {
  id: string;
  placeId: string;
  discount: number;
}

export async function getPlaceActiveCashback(placeId: string): Promise<PlaceCashback$Res | null> {
  try {
    const userSubscriptionsRef = collection(db, "cashbacks");
    const q = query(userSubscriptionsRef, where("placeId", "==", placeId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data() as Omit<PlaceCashback$Res, 'id'>
      return { id: doc.id, ...data };
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

interface UserCashback$Req {
  userId: string;
  placeId: string;
}

export interface UserCashback$Res {
  id: string;
  userId: string;
  placeId: string;
  percentage: number;
  cashbackUsed: number;
  cashbackEarned: number;
  cashbackBalance: number;
  createdAt: any;
  updatedAt: any;
}

export async function getUserCashback(props: Readonly<UserCashback$Req>): Promise<UserCashback$Res | null> {
  const { userId, placeId } = props;

  try {
    const useCashbackRef = collection(db, "userCashbacks");
    const q = query(useCashbackRef, where("userId", "==", userId), where("placeId", "==", placeId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data() as Omit<UserCashback$Res, 'id'>
      return { id: doc.id, ...data };
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

interface UserCashbacks$Req {
  userId: string;
}

export async function getUserCashbacks(props: Readonly<UserCashbacks$Req>): Promise<UserCashback$Res[] | null> {
  const { userId } = props;

  try {
    const useCashbackRef = collection(db, "userCashbacks");
    const q = query(useCashbackRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const p: UserCashback$Res[] = [];
    if (!querySnapshot.empty) {
      querySnapshot.docs.forEach(doc => {
        const data = doc.data() as Omit<UserCashback$Res, 'id'>
        p.push({ id: doc.id, ...data });
      });
      return p;
    } else {
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
}

// export async function getUserCashback(userId: string) {
//   const docRef = doc(db, "userCashbacks", userId);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     return docSnap.data();
//   }
//   return null;
// }

export async function createUserCashback(values: any) {
  const userCashbackRef = collection(db, "userCashbacks");
  const userSubscriptionRef = doc(userCashbackRef);
  const id = userSubscriptionRef.id;
  await setDoc(userSubscriptionRef, { ...values, createdAt: Timestamp.fromDate(new Date()), updatedAt: Timestamp.fromDate(new Date()) });
  return id;
}

export async function updateUserCashback(id: string, values: any) {
  const docRef = doc(db, "userCashbacks", id);
  setDoc(docRef, { ...values, updatedAt: Timestamp.fromDate(new Date()) }, { merge: true })
}

export async function getSubscriptions() {
  try {
    const menuRef = collection(db, "subscriptions");
    const querySnapshot = await getDocs(menuRef);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getActiveSubscriptions() {
  try {
    const userSubscriptionsRef = collection(db, "subscriptions");
    const q = query(userSubscriptionsRef, where("isPublished", "==", true));
    const querySnapshot = await getDocs(q);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    console.log(d);
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getUserSubscriptions() {
  try {
    const menuRef = collection(db, "userSubscriptions");
    const querySnapshot = await getDocs(menuRef);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function createUserSubscription(values: { userId: string, subscriptionId: string } & Record<string, any>) {
  const userSubscriptionsRef = collection(db, "userSubscriptions");
  const userSubscriptionRef = doc(userSubscriptionsRef);
  const id = userSubscriptionRef.id;
  await setDoc(userSubscriptionRef, { ...values, createdAt: Timestamp.fromDate(new Date()) });
  return id;
}

export async function getUserSubscription(userSubscriptionId: string) {
  const docRef = doc(db, "userSubscriptions", userSubscriptionId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function getSubscription(subscriptionId: string) {
  const docRef = doc(db, "subscriptions", subscriptionId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

// export async function getUserSubscription(userId: string) {
//   const userSubscriptionsRef = collection(db, "userSubscriptions");
//   const q = query(userSubscriptionsRef, where("userId", "==", userId), where("isActive", "==", true));
//   const querySnapshot = await getDocs(q);
//   if (querySnapshot.docs.length > 0) {
//     const result = querySnapshot.docs[0]
//     return { id: result.id, ...result.data() };
//   }
//   return null
// }

export async function getPlace(id: string) {
  const docRef = doc(db, "places", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function addPlace(values: { [key: string]: any }) {
  const { id, ...rest } = values;
  const menuRef = collection(db, "places");
  await setDoc(doc(menuRef, id), rest);
}

export async function updatePlace(id: string, values: { [key: string]: any }) {
  const docRef = doc(db, "places", id);
  await setDoc(docRef, values, { merge: true });
}

export function getImageUrl(image: string) {
  const imageRef = ref(storage, image);
  return getDownloadURL(imageRef);
}

export async function getMenuMap(id: string) {
  const docRef = doc(db, "menus", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function getCategoryMap(id: string) {
  const docRef = doc(db, "categories", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function getSelectionMap(id: string) {
  const docRef = doc(db, "selections", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

export async function addMeal(placeId: string, values: any) {
  const docRef = doc(db, "menus", placeId);
  await setDoc(docRef, values, { merge: true });
}

export interface MenuProps {
  id: string;
  placeId: string,
  categoryId: string,
  name: string,
  desc?: string,
  cover?: string;
  price: number,
  selections?: string[];
  addons?: string[],
  isPublic: boolean,
  isSpecial: boolean;
  isFav: boolean;
}

export async function getMenu({ placeId }: { placeId: string }): Promise<MenuProps[]> {
  const menuRef = collection(db, "menu");
  const q = query(menuRef, where("placeId", "==", placeId));
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d as MenuProps[];
}

export async function getCategories({ placeId }: { placeId: string }) {
  const categoryRef = collection(db, "categories");
  const q = query(categoryRef, where("placeId", "==", placeId));
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d;
}

export async function getSelections({ placeId }: { placeId: string }) {
  const categoryRef = collection(db, "selections");
  const q = query(categoryRef, where("placeId", "==", placeId));
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d;
}

export async function getAddons({ placeId }: { placeId: string }) {
  const categoryRef = collection(db, "addons");
  const q = query(categoryRef, where("placeId", "==", placeId));
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d;
}

export async function getOrder({ placeId }: { placeId: string }): Promise<{ categories: string[], menu: string[] } | null> {
  const docRef = doc(db, "order", placeId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as { categories: string[], menu: string[] };
  }
  return null;
}

export async function updateOrder({ placeId, values }: { placeId: string, values: { [key: string]: string[] } }) {
  console.log(placeId, values);
  const docRef = doc(db, "order", placeId);
  console.log(docRef);
  await setDoc(docRef, values, { merge: true });
}


export async function addMenuItem(values: { [key: string]: any }) {
  const menuRef = collection(db, "menu");
  await setDoc(doc(menuRef), values);
}

export async function getMenuLimited(ids: string[]): Promise<any[]> {
  const menuRef = collection(db, "menu");
  const q = query(menuRef, where("id", "in", ids));
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d;
}

export async function getPromoted(placeId: string) {
  const menuRef = collection(db, "menu");
  const q = query(
    menuRef,
    and(where("placeId", "==", placeId),
      or(
        where("isSpecial", "==", true),
        where("isFav", "==", true)
      ))
  );
  const querySnapshot = await getDocs(q);
  const d: any[] = [];
  querySnapshot.forEach((doc) => {
    d.push({ id: doc.id, ...doc.data() });
  });
  return d;
}

export async function updateMenuItem(id: string, values: { [key: string]: any }) {
  const docRef = doc(db, "menu", id);
  await setDoc(docRef, values, { merge: true });
}

export async function addMenuCategory(values: { [key: string]: any }) {
  const categoriesRef = collection(db, "categories");
  const categoryRef = doc(categoriesRef);
  const id = categoryRef.id;
  await setDoc(categoryRef, values);
  return id;
}

export async function updateCategory(id: string, values: { [key: string]: any }) {
  const docRef = doc(db, "categories", id);
  await setDoc(docRef, values, { merge: true });
}

export async function addMenuSelection(values: { [key: string]: any }) {
  const selectionRef = collection(db, "selections");
  await setDoc(doc(selectionRef), values);
}

export async function addMenuAddon(values: { [key: string]: any }) {
  const selectionRef = collection(db, "addons");
  await setDoc(doc(selectionRef), values);
}

export const updateTranslations = (placeId: string, lang: string, values: { [key: string]: { name?: string, desc?: string } }) => {
  console.log(placeId, lang, values);
  const docRef = doc(db, "i18n", `${placeId}-${lang}`);
  setDoc(docRef, values, { merge: true });
}

export const getTransletion = async ({ placeId, lang }: { placeId: string, lang: string }) => {
  console.log('requesting')
  const docRef = doc(db, "i18n", `${placeId}-${lang}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

// AUTH

export enum AUTH_METHOD_SOCIAL {
  FACEBOOK = 'facebook',
  FACEBOOK_REDIRECT = 'facebook_redirect',
  GOOGLE = 'google',
  APPLE = 'apple',
  APPLE_REDIRECT = 'apple_redirect',
}

export function onAuthStateChange(callback: (props?: AuthState) => void) {
  return auth.onAuthStateChanged((user) => {
    if (user) {
      const { uid, isAnonymous, email, displayName, photoURL } = user;
      const providerId = user.providerData?.[0]?.providerId;
      callback({ uid, isAnonymous, email, displayName, photoURL, providerId });
    } else {
      callback(undefined);
    }
  });
}



export function authenticateWithGoogle() {
  return signInWithPopup(auth, provider);
}

export async function authenticateWithFacebook() {
  return signInWithPopup(auth, provider_fb);
}

export async function authenticateWithApple() {
  return signInWithPopup(auth, provider_apple);
}

export async function authenticate(
  method: AUTH_METHOD_SOCIAL
) {
  switch (method) {
    case AUTH_METHOD_SOCIAL.GOOGLE:
      return await authenticateWithGoogle();
    case AUTH_METHOD_SOCIAL.FACEBOOK:
      return await authenticateWithFacebook();
    case AUTH_METHOD_SOCIAL.APPLE:
      return await authenticateWithApple();
    default:
      return undefined;
  }
}

export async function logOut() {
  return await auth.signOut();
}


// AUTH: For admin
export async function googleAuthenticate() {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      window.location.reload();
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

// USER

export async function getUser(uid: string) {
  try {
    // const res = await firebase.firestore().collection('users').doc(uid).get();
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
export async function createUser(uid: string, email: string, photoUrl: string | null, displayName: string | null, dob: any) {
  try {
    const user = {
      photoUrl,
      displayName: displayName || email.split('@')[0],
      email,
      fullName: displayName,
      createdAt: serverTimestamp(),
      dob,
      acceptedTC: true
    };
    // const batch = firebase.firestore().batch();
    // const userRef = firebase.firestore().collection('users').doc(uid);
    const docRef = doc(db, "users", uid);
    await setDoc(docRef, user, { merge: true });
    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
}

export async function updateUser(id: string, values: { [key: string]: any }) {
  const docRef = doc(db, "users", id);
  await setDoc(docRef, values, { merge: true });
}

// ADMIN

export async function getRoles() {
  try {
    const rolesRef = collection(db, "_private");
    const querySnapshot = await getDocs(rolesRef);
    const d: any[] = [];
    querySnapshot.forEach((doc) => {
      d.push({ id: doc.id, ...doc.data() });
    });
    return d as any[];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// User Order

export async function placeOrder(values: any) {
  const categoriesRef = collection(db, "userOrders");
  const categoryRef = doc(categoriesRef);
  const id = categoryRef.id;
  await setDoc(categoryRef, values);
  return id;
}

export async function getUserOrder(orderId: string) {
  const docRef = doc(db, "userOrders", orderId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null
  }
}

export async function deleteUserOrder(orderId: string) {
  const docRef = doc(db, "userOrders", orderId);
  await setDoc(docRef, { isDeleted: true }, { merge: true });
}


export function getUserOrderVerified(orderId: string, cb: any) {
  return onSnapshot(doc(db, "userOrders", orderId), cb);
}

export async function updateUserOrder(orderId: string, values: any) {
  const docRef = doc(db, "userOrders", orderId);
  await setDoc(docRef, values, { merge: true });
}
