import { AddonProps } from "../../../../hooks/useMenuNew";
import Btn from "../../../../ui/Btn/Btn";
import Icon from "../../../../ui/Icon/Icon";
import Space from "../../../../ui/Space/Space";
import Tag from "../../../../ui/Tag/Tag";
import Check from "../../../../ui/form/Check/Check";
import SubHeading from "../../../SubHeading/SubHeading";
import MealAddonOptions from "./MealAddonOptions";

import './MealAddons.css'


interface MealAddonsProps {
    placeId: string;
    addons: Array<AddonProps & { id: string }>
    checked: { [addonId: string]: { [optionId: string]: { quantity: number } } }
    translation?: { [id: string]: { name?: string, desc?: string } },
    addonClear(addonId: string): void;
    addonToggle(addonId: string, optionId: number, optionName: string): void;
    addonQuantity?(addonId: string, optionId: number, value: number): void;
}

const MealAddon: React.FC<{
    addon: AddonProps & { id: string },
    placeId: string,
    checked: { [optionId: string]: { quantity: number } },
    translation?: { [id: string]: { name?: string, desc?: string, noneName?: string } },
    addonToggle(addonId: string, optionId: number, optionName: string): void,
    addonQuantity?(addonId: string, optionId: number, value: number): void,
    addonClear(addonId: string): void
}> = ({ addon, placeId, checked, translation, addonToggle, addonQuantity, addonClear }) => {
    const isNoneSelected = !Object.keys(checked || {}).length;

    const clearAddon = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        addonClear(name)
    }

    return (
        <Space size={8}>
            <SubHeading title={translation?.[addon.id]?.name || addon.name}>{isNoneSelected ? <Tag variant="optional">Optional</Tag> : <Btn size={24} type="success" icon="check" label="check" />}</SubHeading>
            <Space size={16}>
                <Check name={addon.id} type={addon.multi ? "checkbox" : "radio"} checked={isNoneSelected} onChange={clearAddon}>
                    {translation?.[addon.id]?.noneName || addon.noneName}
                </Check>
                <MealAddonOptions
                    placeId={placeId}
                    addonId={addon.id}
                    optionIds={addon.options}
                    multi={addon.multi}
                    checked={checked}
                    translation={translation}
                    toggleChecked={addonToggle}
                    setQuantity={addonQuantity}
                />
            </Space>
        </Space>
    )
}


const MealAddons: React.FC<MealAddonsProps> = (props) => {
    const { placeId, addons, checked, addonClear, addonToggle, addonQuantity } = props;

    return <Space size={24} className="meal-addons">
        {
            addons.map(addon => (
                <>
                    <MealAddon
                        key={addon.id}
                        addon={addon}
                        placeId={placeId}
                        checked={checked[addon.id]}
                        translation={props.translation}
                        addonToggle={addonToggle}
                        addonQuantity={addonQuantity}
                        addonClear={addonClear}
                    />
                    {/* <Space size={8} key={addon.id}>
                        <SubHeading title={addon.name}><Tag variant="optional">Optional</Tag></SubHeading>
                        <Space size={12}>
                            <Check name={addon.id} type={addon.multi ? "checkbox" : "radio"} checked={!Object.keys(checked[addon.id] || {}).length} onChange={clearAddon}>
                                {addon.noneName}
                            </Check>
                            <MealAddonOptions
                                placeId={placeId}
                                addonId={addon.id}
                                optionIds={addon.options}
                                multi={addon.multi}
                                checked={checked[addon.id]}
                                toggleChecked={addonToggle}
                                setQuantity={addonQuantity}
                            />
                        </Space>
                    </Space> */}
                </>
            ))
        }
    </Space>

}

export default MealAddons;