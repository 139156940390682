import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./api/firebase";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./ui/Loading/Loading";
// import './utils/i18n';
import { initReactI18next } from "react-i18next";
import Detector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { auth } from "./api/firebase";
import i18n from "i18next";
import { LOCALE_NS } from "./utils/i18n";


export enum LANGUAGE {
  ET = "et",
  EN = "en",
  RU = "ru",
  FI = "fi",
  LV = "lv",
  PT = "pt",
  IT = "it",
  FR = "fr",
  DE = "de",
  ES = "es",
}

const initialLanguage = localStorage.getItem("lang") || LANGUAGE.ET;

i18n
  .use(Backend)
  .use(Detector)
  .use(initReactI18next)
  .init({
    lng: initialLanguage,
    fallbackLng: LANGUAGE.EN,
    defaultNS: LOCALE_NS.GENERAL,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "p", "u"],
      useSuspense: true,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
  });

i18n.on("languageChanged", (lang: string) => {
  auth.languageCode = lang;
  localStorage.setItem("lang", lang);
});

export const MAPPED_LANGUAGES = Object.values(LANGUAGE);
export default i18n;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </Router>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
