import React from "react";
import "./Space.css";

export interface SpaceProps {
  tag?: "div" | "ul";
  size?: 2 | 4 | 8 | 12 | 16 | 24 | 32 | 48 | 64 | "none";
  className?: string;
  children?: React.ReactNode;
  ref?: any;
}

const Space: React.FC<SpaceProps> = React.memo(
  ({ tag, size = 16, className = "", children, ref }) => {
    const Tag = tag ? tag : "div";
    return (
      <Tag ref={ref} className={`space space--${size} ${className}`}>{children}</Tag>
    );
  }
);

export default Space;
