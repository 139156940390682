import { MealProps } from "../../../../hooks/useMenuNew";
import Check from "../../../../ui/form/Check/Check"
import QuantityField from "../../../../ui/form/QuantityField/QuantityField";
import { currency } from "../../../../utils/currency";

const MealAddonOption: React.FC<{
    addonId: string,
    option: MealProps,
    multi: boolean,
    checked: boolean
    quantity?: number,
    translation?: { [id: string]: { name?: string, desc?: string } },
    toggleChecked(addonId: string, optionId: number, optionName: string): void;
    setQuantity?(addonId: string, optionId: number, value: number): void;
}> = ({ addonId, option, multi, checked, quantity, translation, toggleChecked, setQuantity }) => {
    return <div key={option.id} className="meal-addon-option">
        <Check
            name={addonId}
            type={multi ? "checkbox" : "radio"}
            value={option.id}
            checked={checked}
            onChange={() => toggleChecked(addonId, option.id, option.name)}
        >
            {translation?.[option.id]?.name || option.name}
            <span>{currency(option.price)}</span>
            {checked && quantity && setQuantity && <div className="meal-addon-option__quantity"><QuantityField value={quantity} setFieldValue={(value) => setQuantity(addonId, option.id, value)} /></div>}
        </Check>

    </div>
}

export default MealAddonOption;