import { atom } from 'recoil';

export interface UserState {
  acceptedTC: boolean;
  email: string;
  phone?: string;
  displayName?: string;
  fullName?: string;
  photoUrl?: string;
  createdAt: any;
  dob: any;
  userSubscriptionId?: string;
}

const userState = atom<UserState | undefined>({
  key: 'userState',
  default: undefined,
});

export { userState };
