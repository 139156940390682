import React from "react";
import cn from "clsx";
import "./Section.css";

export interface SectionProps {
  id?: string;
  rounded?: boolean | "right-bottom" | "right-top";
  backgroundImage?: string;
  arrow?: "arrow-down" | "arrow-right" | "arrow-left" | "arrow-up";
  doublePadding?: boolean;
  className?: string;
  fill?:
  | "primary"
  | "secondary"
  | "sub-color"
  | "bg"
  | "bg-alt"
  | "grey1"
  | "grey2"
  | "grey3"
  | "green-dark"
  | "green-light-2"
  | "liliac-dark"
  | "red"
  | "yellow"
  | "orange"
  | "gradi-overlay"
  | "gradi-other";
  border?: "grey1" | "grey3";
  children?: React.ReactNode;
}

const Section: React.FC<SectionProps> = React.memo(
  ({
    id,
    fill,
    border,
    arrow,
    backgroundImage,
    rounded,
    className,
    doublePadding,
    children,
  }) => {
    const BEM = () => {
      return cn("section", className, {
        [`section--${fill}`]: fill,
        [`section--border-${border}`]: border,
        [`section--${arrow}`]: arrow,
        [`section--rounded-${rounded}`]: rounded && rounded !== true,
        "section--arrow": arrow,
        "section--filled": fill || border || backgroundImage,
        "section--rounded": rounded,
        "section--double-padding": doublePadding,
      });
    };

    return (
      <div className={BEM()} id={id}>
        {children}
      </div>
    );
  }
);

export default Section;
