import React from "react";
import cn from "clsx";
import { NavLink } from "react-router-dom";
import "./Button.css";
import Icon, { IconNames } from "../Icon/Icon";

export interface ButtonProps {
  type?:
  | "primary"
  | "secondary"
  | "secondary-alt"
  | "success"
  | "error"
  | "error-link"
  | "link"
  | "link-alt"
  | "facebook"
  | "google"
  | "apple"
  | "outlined"
  | "cashback-offer"
  | "cashback";
  inline?: boolean;
  disabled?: boolean;
  loading?: boolean;
  wrap?: boolean;
  className?: string;
  icon?: IconNames;
  size?: "sm" | 'lg';
  href?: string;
  to?: string;
  children?: React.ReactNode;
  extIcon?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = React.memo(
  ({
    type = "primary",
    inline,
    disabled,
    loading,
    wrap,
    className,
    icon,
    size,
    href,
    to,
    extIcon,
    onClick,
    children,
  }) => {
    const BEM = () => {
      return cn("button", `button--${type}`, className, {
        "button--inline": inline,
        "button--disabled": disabled,
        "button--wrap": wrap,
        [`button--${size}`]: size,
      });
    };

    const renderBody = () => {
      if (loading) {
        return <span>Loading</span>;
      }
      return (
        <>
          {icon && (
            <span className="icon--before">
              <Icon name={icon} />
            </span>
          )}
          <span>{children}</span>
          {extIcon && (
            <span className="icon--after">
              <Icon name="arrow-open-new-tab" size={18} />
            </span>
          )}
        </>
      );
    };

    if (href) {
      return (
        <a
          className={BEM()}
          href={href}
          target={href.startsWith("http") ? "_blank" : undefined}
          rel="noopener noreferrer"
        >
          {renderBody()}
        </a>
      );
    }

    if (to) {
      return (
        <NavLink className={BEM()} to={{ pathname: to }}>
          {renderBody()}
        </NavLink>
      );
    }

    return (
      <button
        className={BEM()}
        type={onClick ? "button" : "submit"}
        disabled={disabled}
        onClick={onClick}
      >
        {renderBody()}
      </button>
    );
  }
);

export default Button;
