import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../ui/Button/Button";
import { QuantityField } from "../../ui/form/QuantityField/QuantityField";
import { ImageSlider } from "../../ui/ImageSlider/ImageSlider";
import Section from "../../ui/Section/Section";
import Space from "../../ui/Space/Space";
import Text from "../../ui/Text/Text";
import { currency } from "../../utils/currency";

import "./Meal.css";
import MealAddons from "./components/MealAddons/MealAddons";
import { orderSelector } from "../../_v2/order";
import MealSelects from "./components/MealSelects/MealSelects";
import Btn from "../../ui/Btn/Btn";
import menuSelector from "../../recoil/menuState";
import { getImageUrl } from "../../api/firebase";
import { getTotalPrice } from "../../hooks/getTotalPrice";
import SubHeading from "../SubHeading/SubHeading";
import { WithTranslation, useTranslation, withTranslation } from "react-i18next";
import { OVERLAY_VIEW, overlayState } from "../../recoil/overlayState";
import { LOCALE_NS } from "../../utils/i18n";
import Icon from "../../ui/Icon/Icon";
import { shortenNumber } from "../../utils/shortenNumber";
import translateSelector from "../../recoil/translationState";


interface MealProps {
  orderId: string;
  mealId: number;
  tableId: string;
  placeId: string;
  isDemo?: boolean;
  onSubmitted: () => void;
  onClose: () => void;
}

const Meal: React.FC<MealProps & WithTranslation> = (props) => {
  const setOverlay = useSetRecoilState(overlayState);
  const { i18n } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const { t } = props;
  const menu = useRecoilValue(menuSelector(props.placeId));
  const meal = menu.menu.find((meal: any) => meal.id === props.mealId);
  const translation = useRecoilValue(translateSelector({ placeId: props.placeId, lang: i18n.language }));
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const mealSelections = useMemo(() => {
    if (meal?.selections) {
      return meal.selections.map((selection: string) => {
        return menu.selectionMap[selection]
      })
    }
    return []
  }, [meal, menu.selectionMap]);



  const mealAddons = useMemo(() => {
    if (meal?.addons) {
      return meal.addons.map((addon: string) => {
        return menu.addonMap[addon]
      })
    }
    return []
  }, [meal, menu.addonMap]);

  const [isDirty, setIsDirty] = useState(false);
  const [selects, setSelects] = useState<{ [selectId: string]: { [optionId: string]: boolean } }>({});
  const [addons, setAddons] = useState<{
    [addonId: string]: { [optionId: string]: { quantity: number, name: string } }
  }>({})

  const addOrderAllowed = useMemo(() => {
    return (meal.selections?.length || 0) !== Object.keys(selects).length;
  }, [meal.selections?.length, selects])

  const [orders, setOrders] = useRecoilState(
    orderSelector({ placeId: props.placeId, tableId: props.tableId })
  );

  const matchingOrder = orders.find((order) => order.id === props.orderId);
  const totalPrice = useMemo(() => getTotalPrice(menu, [{ id: 'temp', mealId: props.mealId, quantity, addons, selects } as any]), [addons, menu, props.mealId, quantity, selects]);

  useEffect(() => {
    if (matchingOrder) {
      setSelects(matchingOrder.selects);
      setAddons(matchingOrder.addons);
      setQuantity(matchingOrder.quantity);
    }
  }, [matchingOrder])

  const fetchImageUrl = useCallback(async () => {
    const cover = meal.cover ? await getImageUrl(`/${props.placeId}/${meal.cover}`) : null;
    setImageUrl(cover);
  }, [meal.cover, props.placeId])

  useEffect(() => {
    fetchImageUrl();
  }, [fetchImageUrl]);

  if (!meal) {
    return null;
  }

  const addOrder = () => {
    if (!props.tableId) {
      setOverlay({
        view: OVERLAY_VIEW.SCAN_TABLE_REQUEST
      })
      return;
    }
    setOrders((prev) => {
      const id = new Date().getTime().toString();
      const order = { id, mealId: props.mealId, quantity, addons, selects }
      return [...prev, order];
    });
    props.onSubmitted();
  };

  const updateOrder = () => {
    setOrders((prev) => {
      return prev.map((order) => {
        if (order.mealId === props.mealId && order.id === props.orderId) {
          const order = { id: props.orderId, mealId: props.mealId, quantity, addons, selects }
          return order;
        }
        return order;
      });
    });
    props.onSubmitted();
  };

  const removeOrder = () => {
    setOrders((prev) => {
      return prev.filter((order) => order.mealId !== props.mealId);
    });
    props.onSubmitted();
  };

  // SELECTS

  const selectsToggle = (selectId: string, optionId: string) => {
    const isChecked = selects[selectId]?.[optionId];
    if (isChecked) {
      setSelects(prev => {
        const next = { ...prev };
        delete next[selectId];
        return next;
      });
    } else {
      setSelects(prev => {
        const next = { ...prev, [selectId]: { [optionId]: true } };
        return next;
      });
    }
    setIsDirty(true);
  }

  // ADDONS

  const addonClear = (name: string) => {
    setAddons(prev => {
      const next = { ...prev };
      delete next[name];
      return next;
    })
    setIsDirty(true);
  }

  const addonToggle = (addonId: string, optionId: number, optionName: string) => {
    const isChecked = addons[addonId]?.[optionId];
    if (isChecked) {
      setAddons(prev => {
        const next = { ...prev };
        delete next[addonId][optionId];
        return next;
      })
    } else {
      setAddons(prev => {
        const next = { ...prev, [addonId]: { ...prev[addonId], [optionId]: { quantity: 1, name: optionName } } };
        return next;
      })
    }
    setIsDirty(true);
  }

  const addonQuantity = (addonId: string, optionId: number, value: number) => {
    if (value === 0) {
      setAddons(prev => {
        const next = { ...prev };
        delete next[addonId][optionId];
        return next;
      })
    } else {
      setAddons(prev => {
        const next = { ...prev, [addonId]: { ...prev[addonId], [optionId]: { ...prev[addonId][optionId], quantity: value } } };
        return next;
      })
    }
  }

  const renderButton = () => {
    if (props.isDemo) {
      return <Button href={`/${props.placeId}`} type="secondary-alt">{t('general:meal.cta.open')}</Button>
    }
    if (matchingOrder && !quantity) {
      return (
        <Button onClick={removeOrder} type="error">
          {t('general:meal.cta.remove')}
        </Button>
      )
    }

    if (matchingOrder && !!quantity) {
      return (
        <Button onClick={updateOrder}>{t('general:meal.cta.update')} | {currency(totalPrice.totalPrice)}</Button>
      )
    }

    if (!matchingOrder) {
      return (
        <Button onClick={addOrder} type="success" disabled={addOrderAllowed}>
          {t('general:meal.cta.add')} &nbsp;|&nbsp; {currency(totalPrice.totalPrice)}
        </Button>
      )
    }
  }

  return (
    <div className="meal">
      {imageUrl && <ImageSlider images={[imageUrl]} forceRatio />}
      {meal.isFav && meal.orderCount && meal.likeRate && <div className="meal__fav">
        <div>
          <Icon name="cart-filled" size={18} />
          <Text size={14}>{shortenNumber(+meal.orderCount)}</Text>
        </div>
        <div>
          <Icon name="like-active" size={18} fill="blue" />
          <Text size={14}>{meal.likeRate}%</Text>
        </div>
      </div>}
      <Section fill="bg">
        <Space>
          <Text size={18} bold>
            {/* {meal.nameI18n[i18n.language]} */}
            {translation?.[meal.id]?.name || meal.name}
          </Text>
          <Text bold color="green-dark">
            {currency(meal.price)}
          </Text>
          <Space size={24}>
            <Text>
              {/* {meal.descI18n[i18n.language]} */}
              {translation?.[meal.id]?.desc || meal.desc}
            </Text>
            {!!mealSelections.length && <MealSelects selects={mealSelections} checked={selects} translation={translation} toggleChecked={selectsToggle} />}
            <MealAddons
              placeId={props.placeId}
              addons={mealAddons}
              checked={addons}
              translation={translation}
              addonClear={addonClear}
              addonToggle={addonToggle}
              addonQuantity={addonQuantity} />
          </Space>
          {meal.allergens && (
            <>
              <SubHeading title={t('general:meal.allergens')}></SubHeading>
              <Text color="secondary">{meal.allergens}</Text>
            </>
          )}
        </Space>
      </Section>
      <div className="meal__close">
        <div className="meal__close-wrapper">
          <Btn icon="close" label="Close overlay" onClick={props.onClose} />
        </div>
      </div>
      {matchingOrder && !!quantity && isDirty && (
        <div className="meal__alt"><Button type="secondary" onClick={addOrder}>
          {t('general:meal.cta.add.new.modified')}
        </Button></div>
      )}
      <div className="meal__sticky">
        <div className="meal__sticky-wrapper">
          <div className="meal__sticky-inner">
            <Section fill="bg-alt">
              <div className="meal__add">
                <QuantityField value={quantity} setFieldValue={setQuantity} />
                {renderButton()}
                {/* {matchingOrder && !quantity && (
              <Button onClick={removeOrder} type="error">
                {t('general:meal.cta.remove')}
              </Button>
            )}
            {matchingOrder && !!quantity && (
              <Button onClick={updateOrder}>{t('general:meal.cta.update')} | {currency(totalPrice.totalPrice)}</Button>
            )}
            {!matchingOrder && (
              <Button onClick={addOrder} type="success" disabled={addOrderAllowed}>
                {t('general:meal.cta.add')} &nbsp;|&nbsp; {currency(totalPrice.totalPrice)}
              </Button>
            )} */}
              </div>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation([LOCALE_NS.GENERAL])(Meal);
