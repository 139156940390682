import { OrderProps } from "../_v2/order";
import { MenuProps } from "../recoil/menuState";

export function getTotalPrice(data: MenuProps, orderedItems?: OrderProps[]) {
    if (!orderedItems || !data) { return { cashier: {}, totalPrice: 0 } }

    const cashier: { [orderId: string]: number } = {};

    for (const item of orderedItems) {
        cashier[item.id] = data.menuMap[item.mealId]?.price || 0;

        // meal selections
        for (const selection of Object.keys(item.selects)) {

            for (const option of Object.keys(item.selects[selection])) {
                if (item.selects[selection][option]) {
                    const result = data.selectionMap[selection]?.options.find((val: any) => val.id === option)
                    cashier[item.id] += result?.extraCost || 0;
                    // cashier[item.id] += selectionOptionMock?.[option]?.extraCost || 0;
                }
            }


        }

        // meal addons quantity
        for (const addonKey of Object.keys(item.addons)) {
            for (const optionKey of Object.keys(item.addons[addonKey])) {
                const result = data.addonMap[addonKey]?.options.find((val: any) => val === optionKey);
                const resultMeal = data.menuMap[result];
                console.log()
                cashier[item.id] += (resultMeal?.price || 0) * (item.addons[addonKey][optionKey].quantity || 1);
                // cashier[item.id] += (addons[+optionKey].quantity * mealPrices[optionKey])
            }
        }

        // meal quantity
        cashier[item.id] = cashier[item.id] * item.quantity;
    }

    const totalPrice = Object.values(cashier).reduce((acc: number, total) => {
        acc += total
        return acc;
    }, 0)

    return { cashier, totalPrice }
}