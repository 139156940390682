import React from "react";
import cn from "clsx";
import { NavLink } from "react-router-dom";
import Icon, { IconNames } from "../Icon/Icon";
import "./Btn.css";

export interface BtnProps {
  label: string; // aria
  inline?: boolean;
  icon: IconNames;
  disabled?: boolean;
  hidden?: boolean;
  type?: "primary" | "secondary" | "secondary-alt" | "outlined" | "active" | "error" | "link" | "success";
  size?: 24 | 32 | 40 | 64;
  align?: "center";
  className?: string;
  iconOnly?: boolean;
  href?: string;
  to?: string;
  onClick?: (e: any) => void;
}

const Btn: React.FC<BtnProps> = React.memo(
  ({
    icon,
    size,
    inline,
    align,
    type = "primary",
    disabled,
    hidden,
    iconOnly,
    href,
    className,
    to,
    onClick,
    label,
  }) => {
    const BEM = () => {
      return cn("btn", `btn--${type}`, className, {
        "btn--logo": icon === "autopin",
        [`btn--${size}`]: size,
        [`btn--inline`]: inline,
        [`btn--${align}`]: align,
        [`btn--disabled`]: disabled,
        [`btn--hidden`]: hidden,
        'bnt--icon-only': iconOnly,
      });
    };

    const renderBody = () => {
      if (icon === "autopin") {
        return <Icon size={24} name={icon} />;
      }
      return <Icon size={18} name={icon} />;
    };
    if (href) {
      return (
        <a
          className={BEM()}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span></span>
          {renderBody()}
        </a>
      );
    }

    if (to) {
      return (
        <NavLink className={BEM()} to={{ pathname: to }}>
          {renderBody()}
        </NavLink>
      );
    }

    return (
      <button
        aria-label={label}
        className={BEM()}
        type={onClick ? "button" : "submit"}
        disabled={disabled}
        onClick={onClick}
      >
        {renderBody()}
      </button>
    );
  }
);

export default Btn;
