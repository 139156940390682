import { atom } from 'recoil';

export interface AuthState {
  uid: string;
  email: string | null;
  isAnonymous: boolean;
  displayName: string | null;
  photoURL: string | null;
  providerId?: string | null;
}

const authState = atom<AuthState | undefined>({
  key: 'authState',
  default: undefined,
});

export { authState };
