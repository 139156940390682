import React from "react";
import { ReactSVG } from "react-svg";
import "./IconImg.css";

export type IconImgNames =
  | "app"
  | "arrow-up-down"
  | "cart"
  | "cashback"
  | "cookies"
  | "delivery"
  | "done"
  | "done-alt"
  | "equipment"
  | "fuel"
  | "google"
  | "insurance-warning"
  | "insurance-error"
  | "insurance"
  | "interval"
  | "less"
  | "like"
  | "location"
  | "logo-detailed"
  | "logo-with-label"
  | "logo"
  | "menual"
  | "mileage"
  | "money-send"
  | "more"
  | "notifications"
  | "oil"
  | "oil-change"
  | "petrol"
  | "pin"
  | "price"
  | "pricetag"
  | "roadside-assistance"
  | "sell"
  | "service"
  | "social"
  | "technical-warning"
  | "technical-error"
  | "technical"
  | "tire"
  | "tour"
  | "translate"
  | "user"
  | "warning"
  | "wave"
  | "wide"
  | "wifi"
  | "windshield"
  | "your-car"
  | "qr";

export interface IconImgProps {
  name: IconImgNames;
  size?: 12 | 14 | 18 | 24 | 32 | 48 | 64 | 84;
  align?: "left" | "center" | "right";
  className?: string;
}

const IconImg: React.FC<IconImgProps> = React.memo(
  ({ name, size = 24, align = "left", className }) => {
    return (
      <ReactSVG
        wrapper="span"
        className={`icon-img icon-img--${size} icon-img--${align} ${className}`}
        src={`/assets/icons-img/${name}.svg`}
      />
    );
  }
);

export default IconImg;
