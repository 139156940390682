import React from "react";
import { ReactSVG } from "react-svg";
import "./Icon.css";
import clsx from "clsx";

export type IconNames =
  | "add-small"
  | "add"
  | "apple"
  | "arrow-down"
  | "arrow-ope-down"
  | "arrow-ope-up"
  | "arrow-ope"
  | "arrow-open-new-tab"
  | "autopin"
  | "back"
  | "body"
  | "bookmark-active"
  | "bookmark"
  | "category"
  | "cart"
  | "cart-filled"
  | "check"
  | "check-fill"
  | "clock"
  | "close"
  | "copy"
  | "dislike"
  | "dislike-fill"
  | "doors"
  | "drivetrain"
  | "edit"
  | "engine-power"
  | "engine"
  | "error"
  | "facebook"
  | "fuel"
  | "google"
  | "hamburger"
  | "hide"
  | "insurance"
  | "instagram"
  | "ios-add"
  | "ios-share"
  | "like-active"
  | "like"
  | "like-fill"
  | "like-double"
  | "like-double-fill"
  | "list"
  | "location"
  | "lock"
  | "mail"
  | "maximum-speed"
  | "messenger"
  | "mycar"
  | "next"
  | "notification"
  | "person"
  | "photo"
  | "pricetag"
  | "remove"
  | "search"
  | "seats"
  | "send"
  | "share"
  | "star"
  | "tel"
  | "transmission"
  | "views"
  | "warning"
  | "wide"
  | "wiser"
  | "table"
  | "table-alt"
  | "twitter";

export interface IconProps {
  name: IconNames;
  size?: 12 | 14 | 18 | 20 | 24 | 32 | 36 | 48 | 64 | 84;
  align?: "left" | "center" | "right";
  className?: string;
  fill?: string;
}

const Icon: React.FC<IconProps> = React.memo(
  ({ name, size = 24, align = "left", className, fill = 'default' }) => {
    const cn = clsx('icon', `icon--${size}`, `icon--${align}`, `icon--${fill}`, className);

    return (
      <ReactSVG
        wrapper="span"
        className={cn}
        src={`/assets/icons/${name}.svg`}
      />
    );
  }
);

export default Icon;
